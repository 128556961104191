<template lang="pug">
app-card-item(:error="error").cm-session
  div.cm-session__item
    app-checkbox(
      :inputValue="value"
      :disabled="!sessionName"
      :value="sessionName"
      :id="sessionName"
      @change="$emit('input', $event)"
    )
    app-select(
      :options="sessions"
      :value="option.session ? option.session.id : null"
      :hide-arrow="!sessions.length"
      slugKey="id"
      slugValue="name"
      return-object
      text
      @input="$emit('update:session', $event)"
    ).cm-session__choose
      span.cm-session__title(slot="input") {{ sessionName ? sessionName : 'Choose session'}}
  div.cm-session__item
    select-course-leader(
      :options="filteredLeaders"
      :disabled="!option.session"
      :value="option.leader ? option.leader.id : []"
      @input="$emit('update:leader', $event)"
    )
    app-button(@click.native="$emit('click:remove', option)" icon)
      <i class="material-icons">close</i>
</template>

<script>
import {mapGetters} from "vuex"
import AppCheckbox from "@/components/global/Checkbox.vue"
import AppButton from '@/components/global/actions/BaseButton.vue'
import appCardItem from "@/components/global/BaseCardItem.vue"
import AppSelect from "@/components/global/actions/BaseSelect.vue"
import SelectCourseLeader from "@/components/global/select/SelectCourseLeader.vue"

export default {
  name: 'cmSession',

  props: {
    option: {
      required: true,
      type: Object
    },
    sessions: Array,
    value: {
      type: Array
    },
    error: Boolean,
    errorMessages: String
  },

  data: () => ({
    checkbox: []
  }),

  computed: {
    ...mapGetters({
      courseLeaders: 'crmCourseLeaders/leadersList'
    }),

    sessionName() {
      if(!this.option.session) return ''
      return this.option.session.name
    },

    filteredLeaders() {
      return this.courseLeaders ? this.courseLeaders : []
    },
  },

  components:  {
    AppCheckbox,
    AppButton,
    AppSelect,
    appCardItem,
    SelectCourseLeader
  }
}
</script>

<style lang="scss">
.cm-session {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;

  &[error="true"] {
    .cm-session__title {
      color: red;
    }
  }

  &__item {
    display: inline-flex;
    align-items: center;
    width: 50%;
  }

  &__title {
    padding-left: 22px;
    cursor: pointer;
  }

  &__choose {
    width: 150px;
    font-weight: 500;
    font-size: 12px;

    .app-input__after {
      display: flex;
      padding-left: 10px;
      cursor: pointer;
    }
  }
}
</style>
